<!-- scholarDataEvaluation -->
<template>
  <div class="w center_all scholarDataEvaluation">
    <div class="center_2">
      <div class="header">
        <ul class="crumbs clearfix">
          <li>邮件推送</li>
          <li>-></li>
          <li>学者数据测评</li>
        </ul>
      </div>
      <div class="content-box">
        <div class="content-box-header">
          <div class="date-range mb-20">
            选择刊期： 起始日期：
            <el-date-picker value-format="yyyy-MM" v-model="dateValue[0]" type="month" placeholder="选择起始日期" :picker-options="startTimeOptions" @focus="clickStartTime" @change="changeDate">
            </el-date-picker>
            ~ 结束日期：
            <el-date-picker value-format="yyyy-MM" v-model="dateValue[1]" type="month" placeholder="选择结束日期" :picker-options="endTimeOptions" @focus="clickEndTime" @change="changeDate">
            </el-date-picker>
          </div>
          <!-- <div class="date-range mb-20">
            选择刊期：<el-date-picker v-model="dateValue" value-format="yyyy-MM" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" @change="changeDate">
            </el-date-picker>
          </div> -->
          <!-- :class="isDisabled ? 'download-btn-disabled' : ''" -->
          <div class="download-btn" @click="downloadReport">
            <!-- <img v-if="isDisabled"  :src="downloadReportDisabledImg" alt="" /> -->
            <img :src="downloadReportImg" alt="" />
            下载报告
          </div>
        </div>
        <div class="explanation mt-20 mb-20">
          <p><span class="bold">学者数据测评说明：</span>截至当前，所选刊期中，</p>
          <p>（1）累计{{ countInfo.count || 0 }}篇文章被{{ countInfo.citationsCount || 0 }}篇文章所引用；</p>
          <p>（2）这{{ countInfo.citationsCount || 0 }}篇文章中，共有{{ countInfo.citationsAuthorArticleCount || 0 }}篇文献的作者在方正学者库中，占比{{ percent1 }}%；</p>
          <p>
            （3）发表在学术期刊上的文献共{{ countInfo.citationsMagArticleCount || 0 }}篇，其引证文献中有{{ countInfo.citationsMagAuthorArticleCount || 0 }}篇的作者在方正学者库中，占比{{ percent2 }}%；
          </p>
          <p v-if="countInfo.citationsArticleCountGroupByType && countInfo.citationsArticleCountGroupByType.length > 0">
            （4）引证文献来源分类统计：
            <span v-for="(item, index) in countInfo.citationsArticleCountGroupByType" :key="index">
              {{ item.type }}{{ item.count }}篇
              <span>{{ index === countInfo.citationsArticleCountGroupByType.length - 1 ? '。' : '，' }}</span>
            </span>
          </p>
        </div>
        <SimpleTable
          :table-data="tableData"
          :column-data="tableColumn"
          :init-obj="tablePageObj"
          @onChangePageData="
            (pageObj) => {
              getTableData(pageObj, GetIssueArticleCitation, 'total')
            }
          "
          @onClickTitle="handleClickTitle"
        />
        <div class="flex-content">
          <!-- 被引文献 -->
          <div class="flex-content-item">
            <h3>被引文献</h3>
            <SimpleTable
              maxHeight="440"
              :table-data="BYWXTableData"
              :column-data="BYWXTableColumn"
              :init-obj="BYWXTablePageObj"
              @onChangePageData="
                (pageObj) => {
                  getTableData(pageObj, GetCountRootArticleCitations, 'BYWX')
                }
              "
            />
          </div>
          <!-- 引证期刊 -->
          <div class="flex-content-item">
            <h3>引证期刊</h3>
            <SimpleTable
              maxHeight="440"
              :table-data="YZQKTableData"
              :column-data="YZQKTableColumn"
              :init-obj="YZQKTablePageObj"
              @onChangePageData="
                (pageObj) => {
                  getTableData(pageObj, GetCountMagCitations, 'YZQK')
                }
              "
            />
          </div>
        </div>
        <div class="flex-content">
          <!-- 被引机构 -->
          <div class="flex-content-item">
            <h3>被引机构</h3>
            <SimpleTable
              maxHeight="440"
              :table-data="BYJGTableData"
              :column-data="BYJGTableColumn"
              :init-obj="BYJGTablePageObj"
              @onChangePageData="
                (pageObj) => {
                  getTableData(pageObj, GetCountRootMagOrg, 'BYJG')
                }
              "
            />
          </div>
          <!-- 引证机构 -->
          <div class="flex-content-item">
            <h3>引证机构</h3>
            <SimpleTable
              maxHeight="440"
              :table-data="YZJGTableData"
              :column-data="YZJGTableColumn"
              :init-obj="YZJGTablePageObj"
              @onChangePageData="
                (pageObj) => {
                  getTableData(pageObj, GetCountCitationsMagOrg, 'YZJG')
                }
              "
            />
          </div>
        </div>
        <div class="flex-content">
          <!-- 被引文献关键词 -->
          <div class="flex-content-item">
            <h3>被引文献关键词</h3>
            <SimpleTable
              maxHeight="440"
              :table-data="BYWXGJCTableData"
              :column-data="BYWXGJCTableColumn"
              :init-obj="BYWXGJCTablePageObj"
              @onChangePageData="
                (pageObj) => {
                  getTableData(pageObj, GetCountRootArticleKeywords, 'BYWXGJC')
                }
              "
            />
          </div>
          <!-- 引证文献关键词 -->
          <div class="flex-content-item">
            <h3>引证文献关键词</h3>
            <SimpleTable
              maxHeight="440"
              :table-data="YZWXGJCTableData"
              :column-data="YZWXGJCTableColumn"
              :init-obj="YZWXGJCTablePageObj"
              @onChangePageData="
                (pageObj) => {
                  getTableData(pageObj, GetCountCitatinsArticleKeywords, 'YZWXGJC')
                }
              "
            />
          </div>
        </div>
        <!-- 被引文献关键词 -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SimpleTable from '@/components/SimpleTable'
import {
  GetIssueArticleCitation,
  SumIssueArticleCitation,
  GetCountRootArticleCitations,
  GetCountMagCitations,
  GetCountRootMagOrg,
  GetCountCitationsMagOrg,
  GetCountRootArticleKeywords,
  GetCountCitatinsArticleKeywords
} from '@/api/emailPush'
export default {
  name: 'scholarDataEvaluation',
  data() {
    return {
      downloadReportImg: require('@/assets/img/download-report.png'),
      downloadReportDisabledImg: require('@/assets/img/download-report-disabled.png'),
      GetIssueArticleCitation,
      GetCountRootArticleCitations,
      GetCountMagCitations,
      GetCountRootMagOrg,
      GetCountCitationsMagOrg,
      GetCountRootArticleKeywords,
      GetCountCitatinsArticleKeywords,
      dateValue: [],
      tableData: [],
      tableColumn: [
        {
          title: '刊期',
          prop: 'yearIusse',
          notSortable: true,
          width: '100px'
        },
        {
          title: '被引文献',
          prop: 'rootTitle',
          notSortable: true,
          special: true
        },
        {
          title: '引证文献',
          prop: 'citationsTitle',
          notSortable: true,
          special: true
        },
        {
          title: '引证文献来源分类',
          prop: 'citationsType',
          notSortable: true
        },
        {
          title: '引证文献作者',
          prop: 'citationsAuthor',
          notSortable: true
        },
        {
          title: '引证期刊',
          prop: 'citationsMag',
          notSortable: true
        },
        {
          title: '引证刊期',
          prop: 'citationsYearIusse',
          notSortable: true,
          width: '100px'
        },
        {
          title: '学者库学者覆盖情况',
          prop: 'citationsAuthorPoolName',
          notSortable: true
        }
      ],
      tablePageObj: {
        page: 1,
        size: 10
      },
      countInfo: {},
      BYWXTableData: [],
      BYWXTableColumn: [
        {
          title: '被引文献',
          prop: 'root_title',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      BYWXTablePageObj: {
        page: 1,
        size: 10
      },
      YZQKTableData: [],
      YZQKTableColumn: [
        {
          title: '引证期刊',
          prop: 'CITATIONS_MAG',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      YZQKTablePageObj: {
        page: 1,
        size: 10
      },
      BYJGTableData: [],
      BYJGTableColumn: [
        {
          title: '被引机构',
          prop: 'INSTITUTION',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      BYJGTablePageObj: {
        page: 1,
        size: 10
      },
      YZJGTableData: [],
      YZJGTableColumn: [
        {
          title: '引证机构',
          prop: 'INSTITUTION',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      YZJGTablePageObj: {
        page: 1,
        size: 10
      },
      BYWXGJCTableData: [],
      BYWXGJCTableColumn: [
        {
          title: '被引文献关键词',
          prop: 'keyword',
          notSortable: true
        },
        {
          title: '频次',
          prop: 'count',
          notSortable: true
        }
      ],
      BYWXGJCTablePageObj: {
        page: 1,
        size: 10
      },
      YZWXGJCTableData: [],
      YZWXGJCTableColumn: [
        {
          title: '引证文献关键词',
          prop: 'keyword',
          notSortable: true
        },
        {
          title: '频次',
          prop: 'count',
          notSortable: true
        }
      ],
      YZWXGJCTablePageObj: {
        page: 1,
        size: 10
      },
      startTimeOptions: {},
      endTimeOptions: {}
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    percent1() {
      let num = ((this.countInfo.citationsAuthorArticleCount || 0) / (this.countInfo.citationsCount || 0)) * 100
      return num ? num.toFixed(2) : 0
    },
    percent2() {
      let num = ((this.countInfo.citationsMagAuthorArticleCount || 0) / (this.countInfo.citationsMagArticleCount || 0)) * 100
      return num ? num.toFixed(2) : 0
    }
  },
  mounted() {
    var now = new Date()
    var year = now.getFullYear() // 得到年份
    var month = now.getMonth() // 得到月份
    month = month + 1 > 9 ? month + 1 : '0' + (month + 1)
    var todayYearDate = year + '-01'
    var todayDate = year + '-' + month
    this.dateValue = [todayYearDate, todayDate]
    this.changeData()
    this.getSumIssueArticleCitation()
  },
  watch: {
    magId() {
      this.changeData()
      this.getSumIssueArticleCitation()
    }
  },
  methods: {
    changeData() {
      this.getTableData({}, GetIssueArticleCitation, 'total')
      this.getTableData({}, GetCountRootArticleCitations, 'BYWX')
      this.getTableData({}, GetCountMagCitations, 'YZQK')
      this.getTableData({}, GetCountRootMagOrg, 'BYJG')
      this.getTableData({}, GetCountCitationsMagOrg, 'YZJG')
      this.getTableData({}, GetCountRootArticleKeywords, 'BYWXGJC')
      this.getTableData({}, GetCountCitatinsArticleKeywords, 'YZWXGJC')
    },
    getTableData(pageParams = {}, URL, type) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        startTime: this.dateValue && this.dateValue.length > 0 ? this.dateValue[0] : '',
        endTime: this.dateValue && this.dateValue.length > 0 ? this.dateValue[1] : '',
        page: page - 1,
        size
      }
      URL(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          if (type === 'total') {
            // 总表
            this.tableData = content
            if (this.tableData.length > 0) {
              this.tableData.map((item) => {
                item.yearIusse = (item.rootYear ? item.rootYear + '年' : '') + (item.rootMagNumber ? item.rootMagNumber + '期' : '')
                item.citationsYearIusse = (item.citationsYear ? item.citationsYear + '年' : '') + (item.citationsNumber ? item.citationsNumber + '期' : '')
              })
            }
            this.tablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'BYWX') {
            // 被引文献
            this.BYWXTableData = content
            this.BYWXTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'YZQK') {
            // 引证期刊
            this.YZQKTableData = content
            this.YZQKTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'BYJG') {
            // 被引机构
            this.BYJGTableData = content
            this.BYJGTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'YZJG') {
            // 引证机构
            this.YZJGTableData = content
            this.YZJGTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'BYWXGJC') {
            // 被引文献关键词
            this.BYWXGJCTableData = content
            this.BYWXGJCTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'YZWXGJC') {
            // 引证文献关键词
            this.YZWXGJCTableData = content
            this.YZWXGJCTablePageObj = {
              page,
              size,
              total: totalElements
            }
          }
        }
      })
    },
    getSumIssueArticleCitation() {
      const params = {
        magId: this.magId,
        startTime: this.dateValue && this.dateValue.length > 0 ? this.dateValue[0] : '',
        endTime: this.dateValue && this.dateValue.length > 0 ? this.dateValue[1] : ''
      }
      SumIssueArticleCitation(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.countInfo = data
        }
      })
    },
    clickStartTime() {
      let endTimeDate = this.dateValue[1]
      this.startTimeOptions = {
        disabledDate: (time) => {
          if (endTimeDate) {
            if (time.getTime() > new Date(endTimeDate).getTime()) {
              return true
            }
            if (time.getTime() > Date.now()) {
              return true
            }
          } else {
            if (time.getTime() > Date.now()) {
              return true
            }
          }
        }
      }
    },
    clickEndTime() {
      let startTimeDate = this.dateValue[0]
      this.endTimeOptions = {
        disabledDate: (time) => {
          if (startTimeDate) {
            if (time.getTime() <= new Date(startTimeDate).getTime() - 24 * 60 * 60 * 1000) {
              return true
            }
            if (time.getTime() > Date.now()) {
              return true
            }
          } else {
            if (time.getTime() > Date.now()) {
              return true
            }
          }
        }
      }
    },
    changeDate() {
      this.changeData()
      this.getSumIssueArticleCitation()
    },
    handleClickTitle(item, columnItem) {
      if (columnItem.prop === 'rootTitle' && item.rootUrl) {
        window.open(item.rootUrl)
      } else if (columnItem.prop === 'citationsTitle' && item.citationsUrl) {
        window.open(item.citationsUrl)
      }
    },
    downloadReport() {
      let routeData = this.$router.resolve({
        name: 'analysisReport',
        query: { magId: this.magId, startTime: this.dateValue && this.dateValue.length > 0 ? this.dateValue[0] : '', endTime: this.dateValue && this.dateValue.length > 0 ? this.dateValue[1] : '' }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang="scss">
.scholarDataEvaluation {
  .content-box {
    .flex-content {
      .conter_3 {
        .conter_page {
          display: block;
          text-align: left;
          .jumpPage {
            float: right;
          }
        }
        .el-table {
          min-height: 440px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.scholarDataEvaluation {
  .content-box {
    text-align: left;
    .content-box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .download-btn {
        display: block;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        color: #4054af;
        display: flex;
        line-height: 27px;
        justify-content: flex-end;
        margin: 30px;
        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
      }
      .download-btn-disabled {
        color: #c0c4cc;
        cursor: no-drop;
      }
    }
    .explanation {
      font-size: 16px;
      .bold {
        font-weight: bold;
      }
    }
    .flex-content {
      display: flex;
      justify-content: space-between;
      .flex-content-item {
        width: 49%;
        text-align: center;
        h3 {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
